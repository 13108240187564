import { Avatar, Box, Divider, Link, Modal, Paper, Typography } from '@material-ui/core';
import React, { Dispatch, FC, Fragment, SetStateAction, memo } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import Cookies from 'js-cookie';
import GetFlag from 'src/components/shared/GetFlag';
import { externalLinks } from 'src/config';
import moment from 'moment';
import zIndex from '@material-ui/core/styles/zIndex';
import { User } from 'src/__generated__/graphql';
import useAuth from 'src/hooks/useAuth';

interface UserDetailModalProps {
  openModal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  user: User;
}

const UserDetailModal: FC<UserDetailModalProps> = ({ openModal, setModal, user }) => {
  const { tournament } = useAuth();
  const isPickleballTournamnet = tournament?.league?.league?.sports?.title?.toLowerCase() === 'pickleball';

  const handleClose = () => {
    setModal(false);
  };

  return (
    <Fragment>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99999999,
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '400px',
            borderRadius: '16px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            zIndex: 99999999,
            height: '100%',
            outline: 'none',
          }}
        >
          <Paper
            elevation={12}
            sx={{
              width: '100%',
              maxHeight: '90vh',
              background: '#FFF',
              p: 0.5,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '7px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#aaa',
                borderRadius: '6px',
                pl: 3,
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#a2a2a2',
              },
            }}
          >
            <Box
              sx={{
                padding: '20px 20px 0px 20px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                color="textPrimary"
                variant="h2"
                sx={{
                  fontFamily: 'Inter, sans-serif',
                }}
              >
                User Details
              </Typography>
              <CloseIcon sx={{ color: 'black', cursor: 'pointer' }} onClick={() => handleClose()} />
            </Box>
            <Box sx={{ padding: '20px 20px 0px 20px' }}>
              <Divider />
            </Box>
            <Box sx={{ padding: '20px 20px 20px 20px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': { color: '#29ABE2' },
                  }}
                >
                  <Avatar
                    sx={{
                      width: '30px',
                      height: '30px',
                      mr: 1,
                    }}
                    src={user?.avatar}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      display: 'flex',
                      width: '-webkit-fill-available',
                      color: '#193660',
                      fontWeight: 500,
                      '&:hover': {
                        color: (theme) => theme.palette.primary.main,
                      },
                      mr: 0.5,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      window.open(`${externalLinks.userProfile + user?.id}?token=${Cookies.get('token')}`);
                    }}
                  >
                    {user?.name ? user?.name : 'Not Added'} {user?.surname ? user?.surname : ''}{' '}
                  </Typography>
                  <GetFlag
                    country={user?.nation}
                    style={{
                      width: '1.4em',
                      height: '1.4em',
                      borderRadius: '1px',
                    }}
                  />
                </Box>
              </Box>
              {user?.gender && (
                <Box
                  sx={{
                    marginBottom: '5px',
                    display: 'flex',
                    mt: 2,
                    gap: '10px',
                    '@media (max-width: 560px)': {
                      flexWrap: 'wrap',
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Gender:
                  </Typography>
                  <Typography variant="body2">{user.gender}</Typography>
                </Box>
              )}
              <Box
                sx={{
                  marginBottom: '5px',
                  display: 'flex',
                  mt: 2,
                  gap: '10px',
                  '@media (max-width: 560px)': {
                    flexWrap: 'wrap',
                  },
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Email:
                </Typography>
                <Typography variant="body2">
                  <Link href={`mailto:${user.email}`}>{user.email}</Link>
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: '5px',
                  display: 'flex',
                  mt: 2,
                  gap: '10px',
                  '@media (max-width: 560px)': {
                    flexWrap: 'wrap',
                  },
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Phone Number:
                </Typography>
                <Typography variant="body2">
                  <Link href={`tel:${user.phone}`}>{user.phone}</Link>
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: '5px',
                  display: 'flex',
                  mt: 2,
                  gap: '10px',
                  '@media (max-width: 560px)': {
                    flexWrap: 'wrap',
                  },
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Date Of Birth:
                </Typography>
                <Typography variant="body2">{moment(user.dob).format('DD/MM/yyyy')}</Typography>
              </Box>
              {isPickleballTournamnet && (
                <Box
                  sx={{
                    marginBottom: '5px',
                    display: 'flex',
                    mt: 2,
                    gap: '10px',
                    '@media (max-width: 560px)': {
                      flexWrap: 'wrap',
                    },
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    DUPR Id:
                  </Typography>
                  <Typography variant="body2">{user?.duprId ?? ''}</Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default memo(UserDetailModal);
