import { gql } from 'src/__generated__/gql';

const TOURNAMENT_REQUESTS = gql(`
  query tournamentRegistrationsRequests($filter: ListTournamentRegistrationInput) {
    tournamentRegistrations(filter: $filter) {
      id
      id
      type
      status
      amount
      createdAt
      payForMyself
      paymentMethod
      paymentStatus
      tournament {
        id
      }
      team {
        title
        country
        image
        members {
         role
         member {
          id
          name
          surname
          citizenship
          nation
          avatar
          rank
          phone
          email
          duprId
         }
        }
      }
      users {
        id
        paymentStatus
        fideTitle
        rank
        blitzElo
        standardElo
        rapidElo
        user {
          id
          nation
          name
          surname
          avatar
          email
          phone
          citizenship
        }
      }
    }
  }
`);

export default TOURNAMENT_REQUESTS;
