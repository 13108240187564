import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation tournamentLogin($email: String!, $password: String!) {
    tournamentLogin(query: { email: $email, password: $password }) {
      id
      email
      accessToken
      name
      surname
      role
      nation
      user_sport
      isAuthenticationRequired
    }
  }
`;

export default LOGIN;
