import moment from 'moment';
import imageCompression from 'browser-image-compression';

export const isArrayCheck = (arr: any) => Array.isArray(arr) && arr.length > 0;

export const findTeamByKey = (key, teams) => {
  if (!key) {
    return null;
  }

  for (const team of teams) {
    if (team[key]) {
      return team;
    }
  }

  return null;
};

export const objectToFactsArray = (inputObject) => {
  const transformedArray =
    inputObject &&
    Object?.entries(inputObject)?.map(([key, value]) => {
      return { factId: parseInt(key), answer: value };
    });

  return transformedArray;
};

export const updateObjectToFactsArray = (inputObject) => {
  const transformedArray =
    inputObject &&
    Object?.entries(inputObject)?.map(([key, value]) => {
      return { tournamentFactId: parseInt(key), answer: value };
    });

  return transformedArray;
};

export const getShortMatchStatus = (status, symbol = '', time = '', isOOP = false) => {
  switch (status) {
    case 'Followed By':
      return `FB ${symbol}${isOOP ? '' : time ?? ''}`;
    case 'Not Before':
      return `NB ${time ?? ''}`;
    case `Not before / After rest`:
      return `NB ${time ?? ''} / After rest`;
    case `Time to be announced / After rest`:
      return `TBA / After rest`;
    case `Specific Time`:
      return `${time ?? ''}`;
    // case `In Progress`:
    //   return status;
    default:
      return `${time ?? ''}`;
  }
};

export const parseToJSON = (value) => {
  let parsedValue = [];
  try {
    parsedValue = value ? JSON.parse(value) : [];
  } catch (error) {
    console.warn('Failed to parse parseVaue:', error);
    parsedValue = [];
  }
  return parsedValue;
};

export const sanitizeFileName = (fileName: string): string => {
  return fileName
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '_')
    .replace(/\./g, '_')
    .replace(/,/g, '_')
    .replace(/[\/\\:*?"<>|]/g, '')
    .replace(/[^a-zA-Z0-9_.-]/g, '');
};

export const downloadFile = async (fileUrl, fileName = ''): Promise<void> => {
  let name = fileName ? fileName : fileUrl?.split('-')[fileUrl?.split('-')?.length - 1] || 'download';

  // Sanitize the filename to avoid special characters
  if (fileName) {
    name = fileName;
  }

  // Sanitize the file name
  name = sanitizeFileName(name);

  const image = await fetch(fileUrl);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function getFideTitle(title) {
  const fideTitles = [
    { title: 'Grandmaster', abbreviation: 'GM', color: '#963BC1' },
    { title: 'International Master', abbreviation: 'IM', color: '#E733A0' },
    { title: 'FIDE Master', abbreviation: 'FM', color: '#FF577A' },
    { title: 'Candidate Master', abbreviation: 'CM', color: '#FF8E5B' },
    { title: 'Woman Grandmaster', abbreviation: 'WGM', color: '#B43FEC' },
    {
      title: 'Woman International Master',
      abbreviation: 'WIM',
      color: '#E733A0',
    },
    { title: 'Woman FIDE Master', abbreviation: 'WFM', color: '#FF577A' },
    { title: 'Woman Candidate Master', abbreviation: 'WCM', color: '#FF8E5B' },
    { title: 'International Arbiter', abbreviation: 'IA', color: 'blue' },
    { title: 'FIDE Arbiter', abbreviation: 'FA', color: 'teal' },
    { title: 'FIDE Instructor', abbreviation: 'FI', color: 'cyan' },
    { title: 'FIDE Organiser', abbreviation: 'IO', color: 'lime' },
    { title: 'National Instructor', abbreviation: 'NI', color: 'yellow' },
    { title: 'National Arbiter', abbreviation: 'NA', color: 'red' },
    { title: 'Developmental Instructor', abbreviation: 'DI', color: 'indigo' },
  ];

  return fideTitles.find((item) => item.title?.toLowerCase() === title?.toLowerCase());
}

export const getTimeControl = (timeCont): string => {
  const timeControl: any = parseToJSON(timeCont);
  return timeControl?.title?.toLowerCase()?.trim() ?? '';
};

export const getRating = (user, timeControl) => {
  const timeCont: any = getTimeControl(timeControl);
  switch (timeCont) {
    case 'blitz':
      return user?.blitzElo === 'Notrated' ? '0' : user?.blitzElo ?? '0';
    case 'rapid':
      return user?.rapidElo === 'Notrated' ? '0' : user?.rapidElo ?? '0';
    case 'standard':
      return user?.standardElo === 'Notrated' ? '0' : user?.standardElo ?? '0';
    default:
      return '0';
  }
};

export const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'ILS':
      return '₪';
    case 'GEL':
      return '₾';
    case 'AED':
      return 'د.إ';
    case 'YEN':
      return '¥';
    case 'RUB':
      return '₽';
    case 'BRAZILIAN REAL':
      return 'R$';
    case 'PLN':
      return 'zł';
    default:
      return '';
  }
};

// export function parseScores(input) {
//   const scoreString = input;
//   const pairs = scoreString?.split(' ');
//   const firstArr = [];
//   const secondArr = [];

//   pairs?.forEach((pair) => {
//     const [first, second] = pair?.split(':');
//     firstArr?.push(first);
//     secondArr?.push(second);
//   });
//   let firstArray = cleanScores(firstArr);
//   let secondArray = cleanScores(secondArr);
//   return {
//     firstArray,
//     secondArray,
//   };
// }

export function parseScores(scoreString) {
  const obj = { firstArray: [], secondArray: [] };

  // Split the input string into sets
  const sets = scoreString?.split(' ');

  sets?.forEach((set) => {
    const match = set?.match(/^\[?(\d+):(\d+)\]?(?:\((\d+):(\d+)\))?$/);

    if (match) {
      const [_, mainScore1, mainScore2, tieScore1, tieScore2] = match;

      obj.firstArray.push({
        mainScore: mainScore1,
        tieScore: tieScore1 || null,
      });

      obj.secondArray.push({
        mainScore: mainScore2,
        tieScore: tieScore2 || null,
      });
    }
  });

  return obj;
}

export const cleanScores = (scores) => {
  return scores?.map((score) => score?.replace(/[^\d()]/g, ''));
};

export const getFormatedScores = (inputScore, match) => {
  const scoresByRound =
    Array.isArray(inputScore) &&
    inputScore?.reduce((acc, score) => {
      const round = score.round;
      if (!acc[round]) {
        acc[round] = [];
      }
      acc[round].push(score);
      return acc;
    }, {});

  let formattedScoresString = '';

  if (typeof scoresByRound === 'object' && scoresByRound !== null) {
    Object?.keys(scoresByRound)?.forEach((round) => {
      const scoresForRound = scoresByRound[round];
      const formattedScores = [];

      const entry1 = scoresForRound[0];
      const entry2 = scoresForRound[1];

      if (entry1 && entry2) {
        if (entry1.entry.id === match?.entry1?.id && entry2?.entry?.id === match?.entry2?.id) {
          entry1.roundFormat === 'tie'
            ? formattedScores.push(`[${entry1.score}:${entry2.score}]`)
            : entry1.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry1.score}:${entry2.score})`)
              : formattedScores.push(`${entry1.score}:${entry2.score}`);
        } else {
          entry2.roundFormat === 'tie'
            ? formattedScores.push(`[${entry2.score}:${entry1.score}]`)
            : entry2.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry2.score}:${entry1.score})`)
              : formattedScores.push(`${entry2.score}:${entry1.score}`);
        }
      }
      formattedScoresString += formattedScores.join(' ') + ' ';
    });
  }

  // Remove the trailing space if needed
  formattedScoresString = formattedScoresString
    .trim()
    .replace(/\s+\(/g, '(') // Remove space before opening (
    .replace(/\)\s+/g, ') ') // Ensure a single space after closing )
    .replace(/\]\s+/g, '] '); // Ensure a single space after closing ]
  return formattedScoresString;
};

export const findEditedMatches = (initializedMatches, targetedMatches) => {
  if (!initializedMatches || !targetedMatches) {
    return [];
  }
  const editedMatches = [];
  Object.keys(initializedMatches).forEach((matchId) => {
    const originalMatch = initializedMatches[matchId];
    const currentMatch = targetedMatches[matchId];

    if (!originalMatch || !currentMatch) {
      return;
    }
    // Check if score or winner.id has changed
    const isWinnerChanged = currentMatch?.winner?.id !== originalMatch?.winner?.id;
    const isTimeChanged = currentMatch?.time !== originalMatch?.time;
    const isCourtChanged = currentMatch?.court?.id !== originalMatch?.court?.id;
    if (Array.isArray(currentMatch?.score)) {
      const currentScore = getFormatedScores(currentMatch?.score, currentMatch);
      const originalScore = getFormatedScores(originalMatch?.score, originalMatch);
      if (currentScore !== originalScore || isWinnerChanged || isTimeChanged || isCourtChanged) {
        editedMatches.push(currentMatch);
      }
    } else {
      if (currentMatch?.score !== originalMatch?.score || isWinnerChanged || isTimeChanged || isCourtChanged) {
        editedMatches.push(currentMatch);
      }
    }
  });
  return editedMatches;
};

export const swapPairsScoreWithBrackets = (inputString) => {
  // if (groupMatch?.entry1.id === groupMatch?.player1ID && groupMatch?.entry2.id === groupMatch?.player2ID) return inputString;

  if (typeof inputString !== 'string') return inputString;

  const regex = /(\[?\d+:\d+\]?)/g;

  const swappedString = inputString?.replace(regex, (pair) => {
    const numbers = pair?.replace(/[\[\]]/g, '')?.split(':');
    return pair?.includes('[') ? `[${numbers?.reverse()?.join(':')}]` : numbers?.reverse()?.join(':');
  });

  return swappedString;
};

export const convertToAbbreviation = (fullString) => {
  const words = fullString?.match(/\[[^\]]+\]|\S+/g);
  const abbreviation = words
    .map((word) => {
      if (word.startsWith('[') && word.endsWith(']')) {
        return word;
      }
      return word.charAt(0).toUpperCase();
    })
    .join('');

  return abbreviation;
};

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getColorByRating = (value) => {
  if (value >= 5) {
    return '#00A100';
  } else if (value >= 4) {
    return '#1F6BFF';
  } else if (value >= 3) {
    return '#FF5733';
  } else {
    return '#8C2135';
  }
};

export const getTournamentStatus = (entryDeadlineDate, tournamentStartDate, tournamentEndDate, tournamentStatus) => {
  const currentDate = new Date();

  const status = (() => {
    if (tournamentStatus === 'inactive') return 'inactive';
    if (currentDate <= new Date(entryDeadlineDate)) return 'registration';
    // if (currentDate >= entryDeadlineDate && currentDate <= tournamentStartDate) return 'deadline past';
    if (currentDate >= new Date(tournamentStartDate) && currentDate <= new Date(tournamentEndDate)) return 'in progress';
    if (currentDate >= new Date(tournamentEndDate)) return 'completed';
    return undefined;
  })();

  const getStepTitle = (): string => {
    switch (status) {
      case 'registration':
        return 'Upcoming';
      case 'in progress':
        return 'In progress';
      case 'completed':
        return 'Completed';
      case 'inactive':
        return 'Inactive';
      default:
        return 'Confirmation';
    }
  };

  return getStepTitle();
};

export const getPreselectedDate = (tournamentStartDate, tournamentEndDate, isManual = false) => {
  const now = moment();
  const currentDate = moment().startOf('day');
  const tournamentStart = moment(tournamentStartDate);
  const tournamentEnd = moment(tournamentEndDate);

  // Check if the tournament is upcoming, ongoing, or past
  if (currentDate.isBefore(tournamentStart)) {
    return tournamentStart.format('YYYY-MM-DD'); // Upcoming tournament: set to the first day
  } else if (currentDate.isAfter(tournamentEnd)) {
    return tournamentEnd.format('YYYY-MM-DD'); // Past tournament: set to the last day
  } else {
    // Ongoing tournament: check time to preselect current or next day
    const preselectNextDay = now.hour() >= 18; // After 17:59 local time
    if (!isManual && preselectNextDay && currentDate.isBefore(tournamentEnd)) {
      return currentDate.add(1, 'day').format('YYYY-MM-DD'); // Next day if ongoing and time is after 18:00
    } else {
      return currentDate.format('YYYY-MM-DD'); // Default to the current date
    }
  }
};

export const getOrdinal = (n) => {
  if (!n) return '';
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/**
 * Compresses an image file to a specified maximum size and dimensions.
 *
 * @param {File} file - The image file to be compressed.
 * @param {number} [maxSizeMB=1] - The maximum file size in megabytes (MB). Default is 1 MB.
 * @param {number} [maxWidthOrHeight=800] - The maximum width or height of the image in pixels. Default is 800 pixels.
 * @returns {Promise<File>} A promise that resolves to the compressed image file.
 *
 * @example
 * compressedImage(file, 0.5, 600).then((compressedFile) => {
 *   console.log('Compressed file:', compressedFile);
 * }).catch((error) => {
 *   console.error('Error while compressing the file:', error);
 * });
 */
export const compressedImage = (file: File, maxSizeMB = 1, maxWidthOrHeight = 800): Promise<File> => {
  const options = {
    maxSizeMB: maxSizeMB, // Max file size in MB
    maxWidthOrHeight: maxWidthOrHeight, // Max width or height of the image
    useWebWorker: true, // Use a web worker for better performance
  };
  return imageCompression(file, options)
    .then((compressedBlob) => {
      const compressedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });

      return compressedFile;
    })
    .catch((error) => {
      console.error('Error while compressing the file:', error);
      throw error;
    });
};
