import { Autocomplete, AutocompleteRenderGetTagProps, Box, Checkbox, Chip, CircularProgress, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import React, { FC, Fragment, ReactNode } from 'react';

interface AutocompleteChipProps<T> {
  label?: string;
  options: T[];
  value: T | T[];
  handleChange: (event: any, value: T | T[]) => void;
  getOptionLabel?: (option: any) => string;
  error?: boolean;
  helperText?: string;
  handleSelectAllChange?: (event: any) => void;
  disabled?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  loading?: boolean;
  placeholder?: string;
  multiple?: boolean;
  noOptionsText?: ReactNode | string;
  size?: 'small' | 'medium';
  background?: string;
  borderColor?: string;
  showPlusMore?: boolean;
  sx?: SxProps;
}

const AutocompleteChip = <T,>({
  label,
  options,
  value,
  getOptionLabel,
  handleChange,
  error,
  helperText,
  handleSelectAllChange,
  disabled,
  isOptionEqualToValue,
  loading,
  placeholder,
  multiple = true,
  noOptionsText,
  size = 'medium',
  background = '#FAFAFA',
  borderColor = 'rgba(10, 37, 64, 0.15)',
  showPlusMore = false,
  sx,
}: AutocompleteChipProps<T>) => {
  const isArray = Array.isArray(value);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        position: 'relative',
      }}
    >
      <Autocomplete
        disabled={disabled}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={multiple}
        autoComplete={false}
        id="draw"
        fullWidth
        sx={{
          ...sx,
          '& .MuiOutlinedInput-root': {
            padding: size === 'small' ? '5px !important' : '8px !important',
            paddingRight: '12px !important',
            '& .MuiAutocomplete-input[placeholder]': { padding: 'inherit !important' },
          },
          '& .MuiFormControl-root': { mt: '0', background: 'inherit !important' },
        }}
        options={options || []}
        getOptionLabel={getOptionLabel}
        value={value ?? null}
        onChange={handleChange}
        clearIcon={null}
        noOptionsText={noOptionsText || 'No options'}
        size={size}
        renderTags={(tagValue: string[], getTagProps: AutocompleteRenderGetTagProps) => {
          if (!showPlusMore) {
            return tagValue.map((option, index) => <Chip {...getTagProps({ index })} label={getOptionLabel ? getOptionLabel(option) : option} size={size} />);
          }

          if (tagValue.length === 0) return null;
          const firstTag = tagValue[0];
          const additionalTagsCount = tagValue.length - 1;
          return (
            <Fragment>
              <Chip
                {...getTagProps({ index: 0 })}
                label={getOptionLabel ? getOptionLabel(firstTag) : firstTag}
                size={size}
                sx={{ width: (getOptionLabel(firstTag) || firstTag)?.length ? '150px' : 'auto' }}
              />
              {additionalTagsCount > 0 && <Chip label={`+${additionalTagsCount}`} size={size} />}
            </Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            fullWidth
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            sx={{
              mt: 1,
              background: background,
              '& .MuiOutlinedInput-root': {
                background: background,
              },

              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor,
                '&:hover': {
                  borderColor: (theme) => `${theme.palette.primary.main} !important`,
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {multiple && options?.length > 0 && (
        <Tooltip title={isArray && options?.length === value?.length ? 'Deselect All' : 'Select All'} placement="top" arrow>
          <Checkbox
            indeterminate={isArray && value?.length > 0 && value?.length < options?.length}
            checked={isArray && value?.length >= options?.length}
            onChange={handleSelectAllChange}
            size="small"
            color="default"
            sx={{
              '& .MuiButtonBase-root': { padding: '0' },
              position: 'absolute',
              top: size === 'small' ? '7px' : '8px',
              right: '35px',
              padding: size === 'small' ? '3px' : '',
              backgroundColor: background,
              '&:hover': {
                backgroundColor: background,
              },
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default AutocompleteChip;
