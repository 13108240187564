import { gql } from '@apollo/client';

const UPDATE_TRANSACTION_HISTORY = gql`
  mutation updateTransactionHistory($id: Int!, $paidAmount: Float!) {
    updateTransactionHistory(updateTransactionHistoryInput: { id: $id, paidAmount: $paidAmount }) {
      id
    }
  }
`;

export default UPDATE_TRANSACTION_HISTORY;
