import { useEffect } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Divider, Typography, Link, Paper, Tooltip } from '@material-ui/core';
import { LoginAuth0, LoginJWT } from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import { makeStyles } from '@material-ui/styles';
import { ReactSVG } from 'react-svg';
import { LoginSocialIcons } from 'src/constants';

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  JWT: '/static/icons/jwt.svg',
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '40px 40px 50px 40px',
    maxWidth: '461px',
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 100,
    background: '#FFF',
    outline: 'none',
    border: '1px solid #0000001A',
    boxShadow: '4px 4px 33.7px 0px #0000001A',
    '&:focus': {
      outline: 'none',
    },
  },
  paperStyle: {
    width: '100%',
    // height: 379,
    background: '#FFF',
    boxShadow: 'none',
    position: 'relative',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    // border:"0px 1px 0px 0px solid "
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '100%',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
}));

const Socials = [
  { label: 'Sign up with Google', icon: '/images/google.svg' },
  { label: 'Sign up with Apple', icon: '/images/apple.svg' },
  { label: 'Sign up with Twitter', icon: '/images/twitter.svg' },
];

interface LoginProps {}

const Login: FC<LoginProps> = ({}) => {
  const classes = useStyles();
  const { platform } = useAuth() as any;

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login </title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          // minHeight: "100vh",
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        {' '}
        <Box>
          <ReactSVG src="/images/darkLogo.svg" style={{ cursor: 'pointer' }} />
        </Box>
        <Box className={classes.modalContainer}>
          <Paper className={classes.paperStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    // fontFamily: "Inter !important",
                    fontSize: '20px !important',
                    fontWeight: ' 500!important',
                    lineHeight: '20px !important',
                    color: '#0A2540 !important',
                  }}
                >
                  Log in to your account
                </Typography>
              </Box>
              {/* {2} */}
              <Box className={classes.loginContainer}>
                <Box className={classes.socialContainer}>
                  <Box
                    sx={{
                      margin: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      <Typography
                        // variant="font21"
                        sx={{
                          lineHeight: '15.6px',
                          color: 'rgba(10, 37, 64, 0.35)',
                          fontFamily: 'Inter, Arial, sans-serif',
                          fontSize: '12px',
                          fontWeight: 500,
                          textAlign: 'left',
                        }}
                      >
                        Don’t have an account?
                      </Typography>
                      <Tooltip title="UNDER=CONSTRUCTION">
                        <Typography
                          // variant="font18"
                          sx={{
                            textDecoration: 'underline',
                            lineHeight: '15.6px',
                            color: '#0A2540',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 500,
                          }}
                          // onClick={() => {
                          //   setSignupModalOpen(true);
                          //   setOpen(false);
                          // }}
                        >
                          Sign up
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '8px', opacity: '0.6' }}>
                      {LoginSocialIcons?.map((item) => <img key={item?.key} src={item?.path} style={{ width: '52px', height: '52px' }} />)}
                    </Box>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Divider>
                      <Typography
                        // variant="font21"
                        sx={{
                          color: 'rgba(10, 37, 64, 0.35)',
                          lineHeight: '15.6px',
                          fontSize: '12px',
                          fontWeight: 400,
                        }}
                      >
                        or log in
                      </Typography>
                    </Divider>
                  </Box>
                </Box>
                <Box className={classes.loginActionContainer}>
                  <Box sx={{ width: '100%' }}>{platform === 'JWT' && <LoginJWT />}</Box>
                  <Box sx={{ margin: 'auto' }}>
                    <Link color="textSecondary" component={RouterLink} to="/authentication/password-recovery">
                      <Typography
                        // variant="font21"
                        sx={{
                          lineHeight: '15.6px',
                          color: '#0A2540',
                          textDecoration: 'underline',
                          fontSize: '12px',
                          fontWeight: 400,
                        }}
                      >
                        Did you forget your password?
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Login;
