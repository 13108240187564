import { gql } from 'src/__generated__/gql';

const TOURNAMENT_TEAM_MEMBERS = gql(`
  query tournamentTeamMembers($filter: ListTournamentTeamMembersInput, $status: String, $isUnique: Boolean, $categoryIds: [Int!]) {
    tournamentTeamMembers(filter: $filter, status: $status, isUnique: $isUnique, categoryIds: $categoryIds) {
      id
      status
      categoryId {
        id
        category {
          name
          id
        }
      }
      registrationRequest {
        id
        status
        createdAt
      }
      duprRating
      user {
        duprId
        rank
        id
        name
        phone
        surname
        avatar
        nation
        email
        gender
        dob
      }
    }
  }
`);

export default TOURNAMENT_TEAM_MEMBERS;
