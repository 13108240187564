import { gql } from '@apollo/client';

const VERIFY_CODE = gql`
  mutation adminOTPVerification($email: String!, $otp: String!) {
    adminOTPVerification(email: $email, otp: $otp) {
      id
      email
      accessToken
      name
      surname
      role
      nation
      user_sport
      isAuthenticationRequired
    }
  }
`;

export default VERIFY_CODE;
