import { gql } from '@apollo/client';

const REMOVE_PARTNER = gql`
  mutation removePartner($id: Int!) {
    removePartner(id: $id) {
      id
    }
  }
`;

export default REMOVE_PARTNER;
